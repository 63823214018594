<template>
  <div class="page client-page" v-loading="loading">
    <div class="content">
      <div>
        <div class="left">
          <div class="title">{{ form.title || "" }}</div>
          <div class="createInfo">
            <div class="user">
              {{ form.createUserName }}
            </div>
            <div class="clock">
              {{
                form.updateTime
                  ? $moment(form.updateTime).format("YYYY-MM-DD")
                  : ""
              }}
            </div>
          </div>
          <div class="article ql-snow">
            <div class="ql-editor" v-html="form.content"></div>
          </div>
        </div>
        <div class="right">
          <div class="title">{{ $t("Tzh.recommendation") }}</div>
          <ul>
            <li
              v-for="item in form.similarCarbonInformationList || []"
              :key="item.id"
            >
              <span @click="getDetils(item.id)">
                {{ item.title }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <el-button
        type="primary"
        style="width: 100px; margin-top: 50px"
        size="medium"
        @click="$router.go(-1)"
        >{{ $t("global.backText") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import api from '@/api'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  data () {
    return {
      loading: true,
      form: {
        similarCarbonInformationList: []
      }
    }
  },
  created () {
    this.getDetils(this.$route.query.id)
  },
  methods: {
    getDetils (id) {
      this.loading = true
      api.tzh.findCarbonInformationById({ id }).then(res => {
        this.loading = false
        this.form = res.data
      })
    }
  }
}
</script>
<style>
.g-main-box .u-main > .u-body {
  padding: 0 !important;
  background-color: #fff;
  overflow-y: auto;
}
/* .ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.article img {
  max-width: 100%;
  margin: 16px 0;
} */
/* pre {
  white-space: normal;
  background-color: #23241f;
  color: #fff;
} */
</style>
<style lang="scss" scoped>
.client-page {
  padding-bottom: 100px;
}
.page {
  display: flex;
  justify-content: center;
  padding-top: 46px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      width: 1200px;
      min-height: 630px;
      display: flex;
      .left {
        max-width: 728px;
        flex: 1;
        margin-right: 66px;
        margin-bottom: 10px;
        height: 100%;
        .title {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 30px;
          color: #000000;
          margin-bottom: 10px;
        }
        .createInfo {
          display: flex;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 16px;
          .user {
            &::before {
              position: relative;
              top: 2px;
              content: "";
              margin-right: 5px;
              display: inline-block;
              width: 14px;
              height: 14px;
              background: url("~assets/img/icon/user-icon.png") no-repeat
                center/cover;
            }
          }
          .clock {
            &::before {
              position: relative;
              top: 2px;
              content: "";
              margin-left: 10px;
              margin-right: 2px;
              display: inline-block;
              width: 14px;
              height: 14px;
              background: url("~assets/img/icon/clock-icon.png") no-repeat
                center/cover;
            }
          }
        }
        .article {
          height: 100%;
        }
      }
      .right {
        width: 332px;
        .title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #000000;
          margin-bottom: 24px;
        }
        ul {
          li {
            &::before {
              content: "";
              margin-right: 8px;
              display: inline-block;
              position: relative;
              width: 4px;
              height: 4px;
              top: 50%;
              transform: translateY(-100%);
              background: #09a666;
              opacity: 0.2;
            }
            overflow: hidden; //溢出隐藏
            white-space: nowrap; //禁止换行
            text-overflow: ellipsis; //...
            margin-bottom: 16px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
            transition: all 0.5s;
            span {
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
</style>
